.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.flatpickr-confirm {
  background: #fff;
  cursor: pointer;
}

.main-wrapper {
  height: 60vh;
  margin: 10px 50px;
  filter: drop-shadow(-1px 5px 3px #ccc);
}
.info-wrapper {
  margin-top: 15px;
}
.map-details {
  text-align: center;
  font-size: 1.2em;
}
.map-details span {
  font-weight: bold;
}
.search-input {
  font-size: 1.2em;
  width: 100%;
}

.pac-container {
  z-index: 1100 !important;
  background-color: #151515;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
   Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
 }
 .pac-item,
 .pac-item-query {
  color: #f7f7f7;
 }
 .pac-item:hover {
  background: #6666;
 }

 .product-badge{
  font-size: 11px;
  font-weight: 400;
  background-color: #ebeff1 !important;
  color: #819ca9 !important;
 }