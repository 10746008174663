
.report-table{
    th{
        padding: 0.2rem 0.75rem;
    }
    & tbody tr {
        cursor: auto !important;
    }
    &__b-blue{
        & td{
            border-bottom: 2px solid #2c93ff !important;
        }
    }
}
tr.no-border-b th{
    border-bottom-width: 0px;
}
.border-b-black{
    border-bottom-color: #495056;
    border-bottom-width: 2px !important;
}
.bg-gray{
    background-color: #e7e7e7 !important;
}