
.report-table{
    th{
        padding: 0.2rem 0.75rem;
    }
    tr:hover{
        background-color: #e7e7e7;
    }
    &__header{
        background-color: #e7e7e7;
    }
    &__col-border{
        &--blue td{
            border-bottom: 2px solid #f46a6a !important;
        }
        &--white td{
            border-bottom: 2px solid #ffffff !important;
        }
    }
    & .f-blue{
        color: #f46a6a;
    }
    & .border-b-black{
        border-bottom-color: #495056;
        border-bottom-width: 2px !important;
    }
    & .border-b-blue{
        border-bottom-color: #f46a6a ;
        border-bottom-width: 2px !important;
    }
    & .bg-gray{
        background-color: #e7e7e7 !important;
    }
}