.breadcrumb-item.active a{
    color: #74788d;
}
.full-spinner{
    position: fixed;
    z-index: 1002;
    background-color: rgb(205 205 205 / 85%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.distribuitor .wrap-container {
    justify-content: left !important;
  }