.arrows-order{
    padding-left: 5px;
    &__asc{
        cursor: pointer;
        color: #afafaf;
        .caret{
            color: #afafaf;
        }
        &.active{
            color: #495057;
            .caret{
                color: #495057;
            }
        }
    }
    &__desc{
        cursor: pointer;
        color: #afafaf;
        &.active{
            color: #495057;
            .caret{
                color: #495057;
            }
        }
        .caret{
            color: #afafaf;
        }
    }
}