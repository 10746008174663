
.report-table{
    th{
        padding: 0.2rem 0.75rem;
    }
    & tbody tr {
        cursor: auto !important;
    }
    &__b-blue{
        & td{
            border-bottom: 2px solid #2c93ff !important;
        }
    }
    &__b-black{
        & td:nth-child(n+4){
            border-bottom-color: #495056;
            border-bottom-width: 2px;
        }
    }
    &__total-customer{
        & td{
            border-bottom: 1px solid #FFFFFF;
            padding-bottom: 20px !important;
        }
    }
    &__total{
        & td:nth-child(n+2){
            color: #2c93ff;
            background-color: #e7e7e7;
        }
        &--border td{
            border-bottom-width: 5px;
            border-bottom-color: #FFFFFF;
        }
    }
}

tr.no-border-b th{
    border-bottom-width: 0px;
}